<template>
  <div class="wrapper static-article">
    <div class="lc-live-portal">
      <div class="live-portal-heading-mobile">
        <div class="live-portal-information">
          <div class="tw-svg">
            <img :src="publicationMastheadLiveCenter">
          </div>
          <h1>Coronavirus</h1> <span>What you need to know:</span>
          <p>TradeWinds gives you the latest updates on the Coronavirus and how it affects the global shipping
            industry.</p>
        </div>
        <div class="covid-map-container">
          <div id="covid-chart-container-mobile"></div>
          <div>Registered cases per 100,000 population</div>
          <div class="case-labels">
            <div class="first-degree"><span></span>
              <div>0 or unknown</div>
            </div>
            <div class="second-degree"><span></span>
              <div>1–9</div>
            </div>
            <div class="third-degree"><span></span>
              <div>10–19</div>
            </div>
            <div class="fourth-degree"><span></span>
              <div>20–39</div>
            </div>
            <div class="fifth-degree"><span></span>
              <div>>40</div>
            </div>
          </div>
          <div class="map-byline">The map is interactive and show cases relative to the population. Source: Johns
            Hopkins University, Worldometers.info. Graphics & service provider: Norkon Computing Systems
          </div>
        </div>
        <div class="numbers-container">
          <div class="number-container">
            <div class="title">Confirmed cases</div>
            <div
              id="covid-total-mobile"
              class="value red"></div>
          </div>
          <div class="number-container">
            <div class="title">Recovered</div>
            <div
              id="covid-total-recovered-mobile"
              class="value green"></div>
          </div>
          <div class="number-container">
            <div class="title">Deaths</div>
            <div
              id="covid-total-deaths-mobile"
              class="value red"></div>
          </div>
        </div>
      </div>
      <div class="live-portal-heading">
        <div class="portal-info-container">
          <div class="live-portal-information">
            <div class="tw-svg">
              <img :src="publicationMastheadLiveCenter">
            </div>
            <h1>Coronavirus</h1>
            <span>What you need to know:</span>
            <p>TradeWinds gives you the latest updates on the Coronavirus and how it affects the global shipping
              industry.</p>
          </div>
          <div class="numbers-container">
            <div class="number-container">
              <div class="title">Confirmed cases</div>
              <div
                id="covid-total"
                class="value red"></div>
            </div>
            <div class="number-container">
              <div class="title">Recovered</div>
              <div
                id="covid-total-recovered"
                class="value green"></div>
            </div>
            <div class="number-container">
              <div class="title">Deaths</div>
              <div
                id="covid-total-deaths"
                class="value red"></div>
            </div>
          </div>
        </div>
        <div class="covid-map-container">
          <div
            id="chart-zoomback"
            style="position:absolute;bottom:10px;right:10px"></div>
          <div
            class="case-labels"
            style="background:rgba(0,26,43,0.38) ">
            <div id="chart-tooltip"></div>
            <div>Registered cases per 100,000 population</div>
            <div class="first-degree"><span></span>
              <div>0 or unknown</div>
            </div>
            <div class="second-degree"><span></span>
              <div>1–9</div>
            </div>
            <div class="third-degree"><span></span>
              <div>10–19</div>
            </div>
            <div class="fourth-degree"><span></span>
              <div>20–39</div>
            </div>
            <div class="fifth-degree"><span></span>
              <div>>40</div>
            </div>
          </div>
          <div>
            <div id="covid-chart-container"></div>
            <div
              class="map-byline"
              style="pointer-events:none;opacity:0.5;padding-top:10px"> The map is
              interactive and show cases relative to the population.<br> Source: Johns Hopkins University,
              Worldometers.info. <br> Graphics & service provider: Norkon Computing Systems
            </div>
          </div>
        </div>
      </div>
      <div id="live-portal-ticker-bar"></div>
      <div class="live-portal-container">
        <div class="twitter-feed">
          <div class="tweet-feed-title">Tweets</div>
          <div id="twitter-container"></div>
          <div style="text-align:center;opacity:0">
            <button
              id="lc-tweet-load-more"
              class="lc-load-more">Vis
              fler
            </button>
          </div>
        </div>
        <div class="lc-default-theme lc-feed-container">
          <div id="master-container"></div>
          <div style="text-align:center;margin-top:15px">
            <button
              id="lc-load-more"
              class="lc-load-more">Load
              more
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Page } from 'global-components';

export default {
  name: 'coronavirus-page',
  extends: Page,
  computed: {
    publicationMastheadLiveCenter() {
      return this.$cdn.resource(`gfx/${this.$pubCtx.name}/masthead-livecenter-coronavirus.svg`);
    }
  },
  embed(head, body) {
    head.style(this.$cdn.resource(`lib/norkon/${this.$pubCtx.name}/livecenter.css`));
    head.add('<script>window.dnViz = { prod: true, bid: "2003311330", jsTemplates: {} }<' + '/script>');

    body.add('<script src="https://livecentercdn.norkon.net/scripts/d3/d3-3.5.4.min.js"><' + '/script>');
    body.add(`<script src="${this.$cdn.resource(`lib/norkon/${this.$pubCtx.name}/livecenter.js`)}"><` + '/script>');
  }
};
</script>
